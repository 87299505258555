

/*
 * @Author: xz
 * @Date: 2022-10-13 21:24:49
 * @LastEditTime: 2023-06-01 22:15:23
 * @LastEditors: xz
 * @Description: 
 */
import request from '../tools/fecth';

// 登录
export function getLogin(data: object) {
  return request(`/player/accountManage/studentLogin`, {
    method: 'get',
    params: data,
    data: { delToken: true },
  });
}
// 获取用户信息
// export function getOperatorInfo() {
//     return request(`/getInfo`,{
//         method: 'get',
//     });
// }
// 获取用户信息
export function getUserInfo(params: any) {
  return request(`/player/accountManage/list`, {
    method: 'get',
    params: params,
  });
}

// 注册
export function Register(query: Object) {
  return request(`/player/accountManage/registerUser`, {
    method: 'post',
    data: { delToken: true, ...query },
  });
}
// 修改用户信息
export function editUser(query: Object) {
  return request(`/player/accountManage/editUser`, {
    method: 'post',
    data: query,
  });
}

// 获取资讯
export function getInformation(data?: object) {
  // return request(`/portal/informationManage/list`,{
  return request(`/portal/informationManage/informationManageList`, {
    method: 'get',
    params: data,
    data: { delToken: true },
  });
}
// 获取资讯 详情
export function getInformationDetalis(id: number | string) {
  // return request(`/portal/informationManage/list`,{
  return request(`/portal/informationManage/portal/${id}`, {
    method: 'get',
    data: { delToken: true },
  });
}
