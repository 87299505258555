import img from "@/asset/page-loading.png"
import './Loading.less'

export default () => {
  return <div className="jump-page-loading">
    <div>
      {/* 菊花隐藏 */}
      {/* <img src={img} alt="" />
      <span>加载中2222...</span> */}
    </div>
  </div>;
}