// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/sunyt/Documents/vk/24-kyber-portal/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/noPermission",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__noPermission__noPermission' */'@/pages/noPermission/noPermission'), loading: LoadingComponent}),
    "exact": true,
    "title": "无权限访问",
    "search": false
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "redirect": "/index",
        "title": "首页",
        "bannerHeaderBG": true,
        "exact": true
      },
      {
        "path": "/index",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index__index' */'@/pages/index/index'), loading: LoadingComponent}),
        "exact": true,
        "title": "凯博元石",
        "bannerHeaderBG": true
      },
      {
        "path": "/industryInformation",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__industryInformation__industryInformation' */'@/pages/industryInformation/industryInformation'), loading: LoadingComponent}),
        "exact": true,
        "title": "凯博快讯",
        "bannerHeaderBG": true
      },
      {
        "path": "/industryInformation/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__industryInformation__details__details' */'@/pages/industryInformation/details/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "快讯详情",
        "bannerHeaderBG": false
      },
      {
        "path": "/developerCommunity",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developerCommunity__developerCommunity' */'@/pages/developerCommunity/developerCommunity'), loading: LoadingComponent}),
        "exact": true,
        "title": "合作共赢",
        "bannerHeaderBG": true
      },
      {
        "path": "/ecologicalPartners",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ecologicalPartners__ecologicalPartners' */'@/pages/ecologicalPartners/ecologicalPartners'), loading: LoadingComponent}),
        "exact": true,
        "title": "产品服务",
        "bannerHeaderBG": true
      },
      {
        "path": "/plan",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__plan__plan' */'@/pages/plan/plan'), loading: LoadingComponent}),
        "exact": true,
        "title": "核心课程",
        "bannerHeaderBG": true
      },
      {
        "path": "/aboutUs",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__aboutUs__aboutUs' */'@/pages/aboutUs/aboutUs'), loading: LoadingComponent}),
        "exact": true,
        "title": "关于我们",
        "bannerHeaderBG": true
      },
      {
        "path": "/study",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__study__study' */'@/pages/study/study'), loading: LoadingComponent}),
        "exact": true,
        "title": "我的学习",
        "bannerHeaderBG": true
      },
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login-register__login__login' */'@/pages/login-register/login/login'), loading: LoadingComponent}),
        "exact": true,
        "title": "登录",
        "bannerHeaderBG": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
