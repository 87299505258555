import { useState, useCallback, useEffect } from 'react'
import { history } from 'umi';

import { getToken, setToken, removeToken, getCus,removeCus } from "@/tools/auth"
import { getUserInfo } from "@/services/public"



export default function useInfoModel() {
  // const [userName, setUserName] = useState<any>(getCus("userName")??null)
  const [userInfo, setUserInfo] = useState<any>(null)
  
  useEffect(() => {
    if (getToken() && getToken() !== "nobody" && !userInfo) {
      getUserInfoFC()
    } else if (!getToken()) {
      // setToken("nobody")
    }
  }, [])


  const getUserInfoFC = () => {
      getUserInfo({userNameEq: getCus("userName")??null}).then((result) => {
        setUserInfo( result.rows[0] )
      }).catch((err) => {
        
      });
  }

  const updataInfo = useCallback(() => {
    getUserInfoFC()
  }, [])

  const signout = useCallback(() => {
    removeCus("userName")
    setUserInfo(null)
    removeToken()
    history.push("/index")
  }, [])

  return {
    userInfo, updataInfo, signout
  }
}

// 使用示例
// import { useModel } from 'umi';

// export default () => {
//   const { user, fetchUser } = useModel('user', model => ({ user: model.user, fetchUser: model.fetchUser }))
//   return <>hello</>
// };