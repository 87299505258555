const TokenKey = 'kb_token'

export function getToken(key?:string) {
  return localStorage.getItem(key?key:TokenKey)
}

export function setToken(token:string) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function getCus(key?:string) {
  return localStorage.getItem(key?key:TokenKey)
}

export function setCus(key:string,token:string) {
  return localStorage.setItem(key, token)
}

export function removeCus(key:string) {
  return localStorage.removeItem(key)
}

export function setSessionKeys(key:string,value:any) {
  return sessionStorage.setItem(key,value)
}
export function getSessionKeys(key:string) {
  return sessionStorage.getItem(key)
}
export function removeSessionKeys(key:string) {
  return sessionStorage.removeItem(key)
}
